import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import InviteGrantMembershipModal from "../../../components/commons/Modals/InviteGrantMembership";
import DropdownWithItems from "../../../components/commons/DropdownWithItems";
import {MANAGE_MEMBERS_FILTER, MANAGE_MEMBERS_SORT} from "../../../constants/space";
import {Link, useParams} from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import {getPercentage} from "antd/es/progress/utils";
import {useSelector} from "react-redux";
import {createSpaceModelComment, deleteSpaceComment, getMembersList, getSpaceChannel, getSpaceChannels, getSpaceDefaultChannel, getSpaceItems, getSpaceUpdates, userPostHeartComment, userPostUnHeartComment, userUpdateHeartComment, userUpdateUnHeartComment} from "../../../services/space";
import ChannelPostForm from "../../../components/commons/Modals/ChannelPostForm";
import ChannelSharePost from "../../../components/commons/Modals/ChannelSharePost";
import default_user_profile from "../../../assets/images/default_user_profile.png";
import {Form} from "antd";
import {VALIDATE_MESSAGES} from "../../../constants/common";
import ReactPlayer from "react-player/lazy";
import Dragger from "antd/es/upload/Dragger";
import {AUDIO_FILETYPES, IMAGE_FILETYPES, UPLOAD_FILE_TYPE, VIDEO_FILETYPES} from "../../../constants/file";
import {getPostDate} from "../../../utils/DateTimeUtils";
import EllipsisDropdown from "../../Channel/EllipsisDropdown";
import {Slide} from "react-slideshow-image";
import routes from "../../../routes";
import {createSpacePost, deleteSpacePost, getSpaceChannelPosts, updateSpacePost, userHeartPost, userPinPost, userUnheartPost, userUnpinPost, userUpdateHeartPost, userUpdateUnheartPost} from "../../../services/post";
import {setPost} from "../../../states/channelSlice";
import {checkImageValidity, checkVideoValidity, getFileExtension} from "../../../utils/file";
import {t} from "i18next";
import {getDataByUsername} from "../../../services/general";
import {setIsNotPublicUrl, setIsPublicUrl} from "../../../states/spaceViewMode";
import {getUserFeed} from "../../../services/user";
import {displayCoverImage, displayDataContent} from "../../../utils/spaceUpdateDataView";

const DashboardHome = () => {

    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);
    const { guid } = useParams();

    const [feeds, setFeeds] = useState([]);

    const [initialLoad, setInitialLoad] = useState(true);

    const [membersCurrentPage, setMembersCurrentPage] = useState(1);
    const [itemsCurrentPage, setItemsCurrentPage] = useState(1);

    const [postForm] = Form.useForm();
    const [commentForm] = Form.useForm();
    const [subCommentForm] = Form.useForm();
    const [subCommentReplyForm] = Form.useForm();
    const [searchForm] = Form.useForm();

    const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
    const [previousPaginationPage, setPreviousPaginationPage] = useState(0);
    const [previousFetchItemsCount, setPreviousFetchItemsCount] = useState(0);
    const [maxPages, setMaxPages] = useState(0);
    const pageListCount = 5; // number of items per page
    const isFooterInViewPort = useSelector((state) => state.footerInView.isFooterInViewPort);

    const postRedux = useSelector((state) => state.channel.post);
    const editPostRedux = useSelector((state) => state.channel.editPost);
    const commentRedux = useSelector((state) => state.channel.comment);
    const editCommentRedux = useSelector((state) => state.channel.editComment);
    const subCommentRedux = useSelector((state) => state.channel.subComment);
    const editSubCommentRedux = useSelector((state) => state.channel.editSubComment);
    const subCommentReplyRedux = useSelector((state) => state.channel.subCommentReply);
    const editSubCommentReply = useSelector((state) => state.channel.editSubCommentReply);
    const subCommentSubReplyRedux = useSelector((state) => state.channel.subCommentSubReply);

    const [alert, setAlert] = useState(null);
    const [fileType, setFileType] = useState(UPLOAD_FILE_TYPE.IMAGE);
    const [imageFiles, setImageFiles] = useState([]);
    const [videoFiles, setVideoFiles] = useState([]);
    const [spaceChannels, setSpaceChannels] = useState([]);
    const [fetchedGuid, setFetchGuid] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const [commentIdOpened, setCommentIdOpened] = useState(null);
    const [subCommentIdOpened, setSubCommentIdOpened] = useState(null);
    const [replyIdOpened, setReplyIdOpened] = useState(null);

    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [channelPostsIsFetching, setChannelPostsIsFetching] = useState(false);
    const [isViewingUserIsMember, setIsViewingUserIsMember] = useState(false);
    const [modelId, setModelId] = useState(null);
    const [commentLevelOneId, setCommentLevelOneId] = useState(null);
    const [subReplyId, setSubReplyId] = useState(null);
    const [commentReplyId, setCommentReplyId] = useState(null);
    const [replyToId, setReplyToId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isBtnPostEnabled, setIsBtnPostEnabled] = useState(false);

    const [spaceData, setSpaceData] = useState(null);
    const [spaceUpdates, setSpaceUpdates] = useState(null);
    const [spaceUsername, setSpaceUsername] = useState(null);
    const [spaceChannel, setSpaceChannel] = useState(null);
    
    const handleChanges = useCallback(() => {})

    const handleCommentChanges = useCallback(() => {})
    // const hasPinnedItem = feeds.some(item => item.is_pinned === true);
    const [isHeartSubmitting, setIsHeartSubmitting] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [isActivationOpen, setActivationOpen] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [submitted, setSubmitted] = useState(false);
    const postIdToDelete = useRef(null);
    const commentIdToDelete = useRef(null);
    const subCommentIdToDelete = useRef(null);
    const subReplyIdToDelete = useRef(null);
    const [isLoadMore, setIsLoadMore] = useState(false);

    const [formInputs, setFormInputs] = useState({});

    const handleInputChange = (id, value) => {
        setFormInputs((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };


    const [updatesCurrentPage, setUpdatesCurrentPage] = useState(1);

    useEffect(() => {
        // fetchViewData('multiverse_spacee').then()
    }, [])




    const getUpdatesRequest = useCallback(async () => {
        try {
            if (authUserInfo) {
                const data = {
                    viewing_user_id: authUserInfo.id,
                    // space_id: 1,
                    // list_count: 10,
                    // page: updatesCurrentPage
                };

                // if (selectedFilter !== 'default') {
                //     data['filter_type'] = filterArr['filter_type'];
                //     data['filter_value'] = filterArr['filter_value'];
                //
                //     if (selectedFilter.includes('category_id_')) {
                //         data['filter_value'] = selectedFilter.replace('category_id_', '');
                //     }
                // }
                //
                // if (selectedSort !== 'default') {
                //     data['sort_by'] = sortArr['sort_by'];
                // }
                //
                // if (searchText && searchText !== '') {
                //     data['search'] = searchText;
                // }
                //
                // if (clickedTableColumnOrder === 'title') {
                //     if (titleSortOrder) {
                //         data['sort_by'] = 'title';
                //         data['sort_order'] = titleSortOrder;
                //     }
                // }
                // if (clickedTableColumnOrder === 'date') {
                //     if (creationDateSortOrder) {
                //         data['sort_by'] = 'date';
                //         data['sort_order'] = creationDateSortOrder;
                //     }
                // }
                // if (clickedTableColumnOrder === 'status') {
                //     if (statusSortOrder) {
                //         data['sort_by'] = 'status';
                //         data['sort_order'] = statusSortOrder;
                //     }
                // }
                // if (clickedTableColumnOrder === 'category') {
                //     if (categorySortOrder) {
                //         data['sort_by'] = 'category';
                //         data['sort_order'] = categorySortOrder;
                //     }
                // }

                // setUpdatesFetching(true);

                const response = await getUserFeed(data);
                if (response && response.result) {
                    if (response.feed) {
                        setFeeds(response.feed);
                        // setTotalUpdatesCount(response.total_updates_count);
                        // buildPagination(parseInt(response.page), parseInt(response.page_range));
                    } else {
                        setFeeds(null);
                    }
                }
                // setUpdatesFetching(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, [
        authUserInfo,
        spaceInfo,
        updatesCurrentPage,
        // selectedFilter,
        // selectedSort,
        // filterArr,
        // sortArr,
        // clickedTableColumnOrder,
        // searchText,
        // titleSortOrder,
        // creationDateSortOrder,
        // statusSortOrder,
        // categorySortOrder
    ]);

    useEffect(() => {
        if (spaceData && authUserInfo) {
            setIsViewingUserIsMember(spaceData.viewing_user_is_member);
        }
    }, [spaceData, guid, authUserInfo]);

    useEffect(() => {
        if (authUserInfo) {
            getUpdatesRequest().then();
        }
    }, [authUserInfo]);

    // useEffect(() => {
    //     const nextPage = currentPaginationPage + 1;
    //     // only trigger next page fetch when:
    //     // - footer is in view port(scrolled to bottom)
    //     // - specified items count per page not be below last fetched data list
    //     const hasMorePages = currentPaginationPage < maxPages;
    //     // if (!channelPostsIsFetching && isFooterInViewPort && (pageListCount === previousFetchItemsCount || hasMorePages)) {
    //     if (!channelPostsIsFetching && spaceChannel) {
    //         setChannelPostsIsFetching(true);
    //         console.log('dashboard')
    //         getChannelPosts(spaceChannel, spaceData, nextPage).then((r) => {});
    //     }
    // }, [
    //     spaceChannel,
    //     spaceData,
    //     isFooterInViewPort,
    //     currentPaginationPage,
    //     previousPaginationPage,
    //     previousFetchItemsCount,
    //     maxPages,
    // ]);

    // const getChannelPosts = useCallback(async (channel, space, pageNumber = 1) => {
    //         console.log('channel')
    //         console.log(channel)
    //         if (!channel) {
    //             return;
    //         }
    //         // if (!spaceUpdates || (spaceUpdates && spaceUpdates.length === 0) || isActivationOpen) {
    //         //     setChannelPostsIsFetching(true);
    //         // }
    //         try {
    //             setChannelPostsIsFetching(true);
    //             const params = {
    //                 space_id: space.id,
    //                 channel_id: channel.id,
    //                 page: pageNumber,
    //                 listCount: pageListCount,
    //             };
    //
    //             if (authUserInfo) {
    //                 params.viewing_user_id = authUserInfo.id;
    //             }
    //             const response = await getSpaceChannelPosts(params);
    //             console.log('response')
    //             console.log(response)
    //             const channelPost = response.posts;
    //             if (!spaceUpdates && response && channelPost && response.result) {
    //                 setMaxPages(response.page_range);
    //                 setPreviousFetchItemsCount(channelPost.length);
    //                 channelPost.forEach(key => {
    //                     if(!feeds.find(obj2 => obj2.id === key.id)) {
    //                         setFeeds(prevPosts => [...prevPosts, key]);
    //                     }
    //                 });
    //             }
    //             setCurrentPaginationPage(pageNumber);
    //             setPreviousPaginationPage(currentPaginationPage);
    //             // if (response.result) {
    //             //     setSpaceUpdates(response.result);
    //             //     // setUpdatesViewingUserIsMember(result.viewing_user_is_member);
    //             // } else {
    //             //     setSpaceUpdates(null);
    //             // }
    //             setIsLoadMore(false);
    //             setTimeout(() => {
    //                 setChannelPostsIsFetching(false);
    //             }, 1000);
    //
    //         } catch (error) {
    //             console.log(error);
    //             setChannelPostsIsFetching(false);
    //         }
    //     },
    //     [
    //         authUserInfo,
    //         spaceUpdates,
    //         currentPage,
    //         feeds,
    //         feeds,
    //         currentPaginationPage,
    //         previousPaginationPage,
    //         channelPostsIsFetching,
    //     ]
    // );

    const setReplyModelId = (modelId, replyId) => {
        setModelId(modelId);
        setReplyToId(replyId);
    }

    // const handleChangeChannel = (username = null, guid) => {
    //     window.location.replace('/@' + (username ? username : spaceUsername) + '/channel/'+guid);
    // }

    const setSubReplyModelId = (modelId, levelOneId, replyId) => {
        if (modelId) setModelId(modelId);
        if (levelOneId) setCommentLevelOneId(levelOneId);
        if (replyId) setReplyToId(replyId);
    }

    // const handlePostChanges = useCallback((event) => {
    //     setIsBtnPostEnabled((imageFiles.length > 0 || videoFiles.length > 0 || audioFile) || event.target.value.length > 0);
    // })

    // const dummyRequest = ({ file, onSuccess }) => {
    //     setTimeout(() => {
    //         onSuccess('ok');
    //     });
    // };

    // const handleSearchPosts = useCallback(async (searchText) => {
    //         setIsSubmitting(true);
    //         const channel_param = {
    //         }
    //         if (spaceChannel) {
    //             channel_param.channel_id = spaceChannel?.id;
    //         }
    //         if(searchForm.getFieldValue('search') !== undefined && searchForm.getFieldValue('search').trim() !== ''){
    //             channel_param.search = searchForm.getFieldValue('search') ;
    //         }
    //         channel_param.viewing_user_id = 1;
    //         channel_param.page = currentPage;
    //         // const result = await getSpaceUpdates(params);
    //         const channel_posts = await getSpaceChannelPosts(channel_param);
    //         if(channel_posts && channel_posts.result){
    //             setFeeds(channel_posts.posts);
    //             setIsSubmitting(false);
    //         }
    //     }, [spaceChannel, feeds, currentPage]
    // )

    // const handleUploadFileChange = async (file) => {
    //     setAlert(null);
    //     const file_extension = getFileExtension(file);
    //     const objectUrl = URL.createObjectURL(file);
    //
    //     if (VIDEO_FILETYPES.includes('.' + file_extension.toLowerCase())) {
    //         const vidValid = await checkVideoValidity(file, alert);
    //         if (!vidValid) {
    //             return false;
    //         }
    //         setFileType(UPLOAD_FILE_TYPE.VIDEO);
    //         setVideoFiles([{file: file, uri: objectUrl}]);
    //         setAudioFile(null);
    //         setImageFiles([]);
    //         setIsBtnPostEnabled(true);
    //     }
    //     if (IMAGE_FILETYPES.includes('.' + file_extension.toLowerCase())) {
    //         const imgValid = await checkImageValidity(file, alert);
    //         if (!imgValid) {
    //             return false;
    //         }
    //         setFileType(UPLOAD_FILE_TYPE.IMAGE);
    //         let imageArray = [];
    //         imageArray = imageArray.concat(imageFiles); // Combine arrays
    //         imageArray.push({file: file, uri: objectUrl});
    //         setImageFiles(imageArray);
    //         setVideoFiles([]);
    //         setAudioFile(null);
    //         setIsBtnPostEnabled(true);
    //     }
    //     if (AUDIO_FILETYPES.includes('.' + file_extension.toLowerCase())) {
    //         setFileType(UPLOAD_FILE_TYPE.AUDIO);
    //         setAudioFile({file: file, uri: objectUrl});
    //         setImageFiles([]);
    //         setVideoFiles([]);
    //         setIsBtnPostEnabled(true);
    //     }
    // };

    const toggleHeart = useCallback(async (id, state) => {
        if (!state) {
            state = false;
        }
        if (authUserInfo) {
            const data = {
                user_id: authUserInfo.id,
                update_id: id
            };
            setIsHeartSubmitting(true);
            let response = {}
            if(state){
                response = await userUpdateUnheartPost(data);
            } else{
                response = await userUpdateHeartPost(data);
            }
            if(response && response.result){
                setIsHeartSubmitting(false);
                let post = response.post;
                if (post) {
                    let post_index = feeds.findIndex(x => x.id === post.id);
                    let newState = JSON.parse(JSON.stringify(feeds));
                    newState[post_index].hearted_by_users = post.hearted_by_users;
                    newState[post_index].hearts_count = post.hearts_count;
                    newState[post_index].is_hearted = !state;
                    // Update the state with the modified copy
                    setFeeds(newState);
                }
            } else {
                setIsHeartSubmitting(false);
            }
        }
    }, [feeds, authUserInfo])

    const handleDropdownSelection = (selection, data, type) => {
        setSelectedAction(selection);
        if (selection === 'delete'){
            setDataToDelete(data.id, type).then();
        }else if (selection === 'edit'){
            setActivationOpen(true);
            setDataToEdit(data)
        }else{
            // setDataToPin(data).then();
        }
    }

    const setIdsForDeletion = (postId, commentId=null, subCommentId=null, subreplyId = null) => {
        if (postId){
            postIdToDelete.current = postId;
        }
        if (commentId){
            commentIdToDelete.current = commentId;
        }
        if (subCommentId){
            subCommentIdToDelete.current = subCommentId;
        }
    }

    const setDataToPin = useCallback(async (data) => {
        const params = {
            user_id: authUserInfo.id,
            post_id: data.id
        };
        let post_index = feeds.findIndex(x => x.id === data.id);
        setIsSubmitting(true);
        let post = {...data};
        if(data.is_pinned){
            const response = await userUnpinPost(params);
            post.is_pinned = false;
            feeds[post_index] = post;
            setIsSubmitting(false);
        }else{
            const response = await userPinPost(params);
            post.is_pinned = true;
            feeds[post_index] = post;
            setIsSubmitting(false);
        }}, [authUserInfo, feeds]
    )

    const processPostsCount = (isIncreaseCount, spaceChannel) => {
        setSpaceChannels(prev => {
            return prev.map((channel) => {
                if (channel.id === spaceChannel?.id) {
                    return {...channel, posts_count: isIncreaseCount ? (channel.posts_count + 1) : channel.posts_count - 1};
                } else {
                    return channel;
                }
            });
        });
    }

    const attachmentSubmit = useCallback(async (values) => {
        setIsSubmitting(true);
        const update = {};
        update.model = 'post';
        update.channel_id = spaceChannel?.id;
        update.title = values.post ? values.post : '';
        update.content = '';
        const attachments = [];
        const attachment = {};
        if (audioFile){
            attachment.id = "null";
            attachments.push(attachment);
            update.attachments_type = 'audio'
            update.attachments = JSON.stringify(attachments);
            update.file_0 = audioFile.file;
        }
        if (imageFiles.length > 0){
            imageFiles.map((image, index) => {
                attachment.id = "null";
                attachments.push(attachment);
                update.attachments_type = 'image'
                update.attachments = JSON.stringify(attachments);
                const key = `file_${index}`; // Create a dynamic key (e.g., file_0, file_1, ...)
                update[key] = image.file; // Assign the file object to the dynamic key
            })
        }
        if (videoFiles.length > 0){
            videoFiles.map((video, index) => {
                attachment.id = "null";
                attachments.push(attachment);
                update.attachments_type = 'video'
                update.attachments = JSON.stringify(attachments);
                const key = `file_${index}`; // Create a dynamic key (e.g., file_0, file_1, ...)
                update[key] = video.file; // Assign the file object to the dynamic key
            })
        }
        try {
            if (authUserInfo) {
                update.user_id = authUserInfo.id;
                update.space_id = spaceData.id;
                const response = await createSpacePost(update);
                // handleNewData(response)
                if (response && response.result) {
                    postForm.resetFields();
                    dispatch(setPost(response.post));
                    setImageFiles([]);
                    setVideoFiles([]);
                    setAudioFile(null);
                    setIsBtnPostEnabled(true);
                    setIsSubmitting(false);
                } else {
                    window.scrollTo(0, 0);
                }
                // setModalOpen(false);
            }
        } catch (error) {
            console.log(error);
        }},[imageFiles, audioFile, videoFiles, fileType]
    );
    
    const setDataToDelete = useCallback(
        async (values, type) => {
            const data = {};
            setIsSubmitting(true);
            if(type === "post"){
                data.post_id = values;
                const response = await deleteSpacePost(data);
                if (response && response.result) {
                    let post_index = feeds.findIndex(x => x.id === values);
                    feeds.splice(post_index, 1);
                    // processPostsCount(false, spaceChannel);
                }

            }else if(type === "comment"){
                data.comment_id = values;
                const response = await deleteSpaceComment(data);
                if (response && response.result) {
                    let post_index = feeds.findIndex(x => x.id === postIdToDelete.current);
                    let comment_index = feeds[post_index].comments.findIndex(x => x.id === commentIdToDelete.current);
                    feeds[post_index].comments.splice(comment_index, 1);

                }
            }else if(type === "sub-comments"){
                data.comment_id = values;
                const response = await deleteSpaceComment(data);
                if (response && response.result) {
                    let post_index = feeds.findIndex(x => x.id === postIdToDelete.current);
                    let comment_index = feeds[post_index].comments.findIndex(x => x.id === commentIdToDelete.current);
                    let sub_comment_index = feeds[post_index].comments[comment_index].replies.findIndex(x => x.id === subCommentIdToDelete.current);
                    feeds[post_index].comments[comment_index].replies.splice(sub_comment_index, 1);

                }
            }
            else if(type === "sub-replies"){
                data.comment_id = values;
                const response = await deleteSpaceComment(data);
                if (response && response.result) {
                    let post_index = feeds.findIndex(x => x.id === postIdToDelete.current);
                    let comment_index = feeds[post_index].comments.findIndex(x => x.id === commentIdToDelete.current);
                    let sub_comment_index = feeds[post_index].comments[comment_index].replies.findIndex(x => x.id === subCommentIdToDelete.current);
                    let sub_reply_index = feeds[post_index].comments[comment_index].replies[sub_comment_index].sub_replies.findIndex(x => x.id === subReplyIdToDelete.current);
                    feeds[post_index].comments[comment_index].replies[sub_comment_index].sub_replies.splice(sub_reply_index, 1);

                }
            }
            setIsSubmitting(false);
        }, [feeds, postIdToDelete, commentIdToDelete, subCommentIdToDelete, subReplyIdToDelete, spaceChannel, spaceChannels])

    const handleSubmitSubComment = useCallback(async (values) => {
            let okToSubmit = (subCommentForm.getFieldValue('reply') !== undefined && subCommentForm.getFieldValue('reply').trim() !== '');
            setIsSubmitting(false)
            if (okToSubmit) {
                setSubmitted(true);
                const data = {
                    space_id: spaceChannel?.id,
                    model: 'update',
                    model_id: modelId,
                    comment: values.reply,
                    reply_to_id: replyToId
                };
                if (authUserInfo) {
                    data['user_id'] = authUserInfo.id;
                }
                const response = await createSpaceModelComment(data);
                if (response && response.result) {
                    // updateCommentIdToReply(null);
                    // fetchUpdatedComments();
                    let post_index = feeds.findIndex(x => x.id === modelId);
                    let comment_index = feeds[post_index].comments.findIndex(x => x.id === replyToId);
                    if(feeds[post_index].comments[comment_index].replies){
                        feeds[post_index].comments[comment_index].replies.unshift(response.comment);
                    }else{
                        feeds[post_index].comments[comment_index].replies = new Array();
                        feeds[post_index].comments[comment_index].replies.unshift(response.comment);
                    }
                    subCommentForm.resetFields();
                    setSpaceUpdates(null)
                    setIsSubmitting(false);
                }
                setSubmitted(false);
                // setDisableReplyButton(true);
            }
        },
        [authUserInfo, spaceChannel, commentReplyId, replyToId, modelId, feeds]
    );

    const handleSubmitSubReply = useCallback(async (values) => {
            let okToSubmit = (subCommentReplyForm.getFieldValue('reply') !== undefined && subCommentReplyForm.getFieldValue('reply').trim() !== '');
            setIsSubmitting(true)
            if (okToSubmit) {
                setSubmitted(true);
                const data = {
                    // space_id: spaceChannel?.id,
                    space_id: 1,
                    model: 'update',
                    model_id: modelId,
                    comment: values.reply,
                    reply_to_id: replyToId
                };
                if (subReplyId) {
                    data['reply_to_sub_id'] = subReplyId;
                }
                if (authUserInfo) {
                    data['user_id'] = authUserInfo.id;
                }
                const response = await createSpaceModelComment(data);
                if (response && response.result) {
                    let post_index = feeds.findIndex(x => x.id === modelId);
                    let comment_index = feeds[post_index].comments.findIndex(x => x.id === commentLevelOneId);
                    let reply_index = feeds[post_index].comments[comment_index].replies.findIndex(x => x.id === replyToId);
                    if(reply_index >= 0 && feeds[post_index].comments[comment_index].replies[reply_index].sub_replies){
                        feeds[post_index].comments[comment_index].replies[reply_index].sub_replies.push(response.comment);
                    } else{
                        let reply = {...feeds[post_index].comments[comment_index].replies[reply_index]};
                        reply.sub_replies = [];
                        feeds[post_index].comments[comment_index].replies[reply_index] = reply;
                        feeds[post_index].comments[comment_index].replies[reply_index].sub_replies.push(response.comment);
                    }
                    subCommentReplyForm.resetFields();
                    setIsSubmitting(false);
                    setSpaceUpdates(null);
                    setCommentLevelOneId(null);
                }
                setSubmitted(false);
                // setDisableReplyButton(true);
            }
        },
        [authUserInfo, spaceChannel, commentReplyId, replyToId, modelId, subReplyId, commentLevelOneId]
    );

    const handleCommentSubmit = useCallback(async (values) => {
            let okToSubmit = (values.comment !== undefined && values.comment.trim() !== '');
            if (okToSubmit) {
                setIsSubmitting(true)
                setSubmitted(true);
                const data = {
                    space_id: 1,
                    model: 'update',
                    model_id: modelId,
                    // reply_to_id: replyToId,
                    comment: values.comment
                    // channel_id: spaceChannel?.id,
                };
                if (authUserInfo) {
                    data['user_id'] = authUserInfo.id;
                }
                const response = await createSpaceModelComment(data);
                commentForm.resetFields();
                if (response && response.comment) {
                    let index = feeds.findIndex(x => x.id === modelId);
                    const post = feeds[index];
                    // console.log(post)
                    if(post.comments && post.comments.length === 0){
                        post.comments.push(response.comment);
                    } else {
                        if (!post.comments) {
                            post.comments = [];
                        }
                        post.comments.unshift(response.comment);
                    }
                    setSpaceUpdates(null)
                    setIsSubmitting(false);
                    setModelId(null);
                }
                setSubmitted(false);
            }
        },
        [
            authUserInfo,
            spaceData,
            modelId,
            feeds
        ]
    );

    const toggleHeartComment = useCallback(async (update_id, state, thread_id=null, sub_thread_id=null, sub_thread_reply_id=null) => {
        state = !state;
        if (authUserInfo) {
            const data = {
                user_id: authUserInfo.id,
                update_id: update_id
            };
            if (thread_id) {
                data.thread_id = thread_id;
            }
            if (sub_thread_id) {
                data.sub_thread_id = sub_thread_id;
            }
            if (sub_thread_reply_id) {
                data.sub_thread_reply_id = sub_thread_reply_id;
            }
            setIsHeartSubmitting(true);
            let response = {}
            if (state) {
                response = await userUpdateUnHeartComment(data);
            } else{
                response = await userUpdateHeartComment(data);
            }
            if(response && response.result){
                setIsHeartSubmitting(false);
                // let comment = response.comment;
                // if (comment) {
                //     let post_index = feeds.findIndex(x => x.id === post_id);
                //     if (thread_id && sub_thread_id == null && sub_thread_reply_id === null) {
                //         let thread_index = feeds[post_index].comments.findIndex(x => x.id === thread_id);
                //         feeds[post_index].comments[thread_index].hearted_by_users = comment?.hearted_by_users
                //         feeds[post_index].comments[thread_index].hearts_count = comment?.hearts_count
                //         feeds[post_index].comments[thread_index].is_hearted = comment?.is_hearted
                //
                //     } else if (thread_id && sub_thread_id && sub_thread_reply_id === null) {
                //
                //         let thread_index = feeds[post_index].comments.findIndex(x => x.id === thread_id);
                //         let sub_thread_index = feeds[post_index].comments[thread_index].replies.findIndex(x => x.id === sub_thread_id);
                //         // comments[thread_index].replies[sub_thread_index] = comment
                //         feeds[post_index].comments[thread_index].replies[sub_thread_index].hearted_by_users = comment?.hearted_by_users
                //         feeds[post_index].comments[thread_index].replies[sub_thread_index].hearts_count = comment?.hearts_count
                //         feeds[post_index].comments[thread_index].replies[sub_thread_index].is_hearted = comment?.is_hearted
                //
                //     } else if (thread_id && sub_thread_id && sub_thread_reply_id) {
                //
                //         let thread_index = feeds[post_index].comments.findIndex(x => x.id === thread_id);
                //         let sub_thread_index = feeds[post_index].comments[thread_index].replies.findIndex(x => x.id === sub_thread_id);
                //         let sub_thread_reply_index = feeds[post_index].comments[thread_index].replies[sub_thread_index].sub_replies.findIndex(x => x.id === sub_thread_reply_id);
                //         feeds[post_index].comments[thread_index].replies[sub_thread_index].sub_replies[sub_thread_reply_index] = comment
                //
                //     }
                // }
            } else {
                setIsHeartSubmitting(false);
            }
        }
    }, [authUserInfo, feeds])


    return (
        <div className="w-full centerframe-grid flex">
            <div className="block-2xl space-y-0 order-0">
                <div className="cs-stack-channel-header stack-col mb-article hidden lg:flex">
                    <div className="stack-col-min">
                        <div className="stack-col sm:stack-row split">
                            <h2>Feed</h2>
                            <div className="cs-stack-filters">
                                <button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>All</p><i className="fa-regular fa-angle-down"></i></button>
                                <button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>Newest</p><i className="fa-regular fa-angle-down"></i></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cs-stack-feed-col stack-col">
                    {feeds && feeds.map((feed, index) => (
                        <article key={index} className="cs-post block-full card-base">
                            <div className="stack-col p-article">
                                <div className="cs-stack-post-header stack-row split">
                                    <div className="stack-row">
                                        <a className="cs-post-avatar relative inline-block">
                                            <img className="h-16 w-16 rounded-full object-cover"
                                                 src={feed?.author_data?.photo_thumb || default_user_profile}
                                                 alt=""
                                            />
                                        </a>
                                        <hgroup className="title">
                                            <h4>
                                                <a>
                                                    @{feed?.author_data?.username ? feed?.author_data?.username : feed?.author_data?.full_name}
                                                </a>
                                            </h4>
                                            <p className="text-p4 sm:text-p3">{getPostDate(feed?.created_on)}</p>
                                        </hgroup>
                                    </div>
                                    <a className="button-icon-base-gradient">
                                        {authUserInfo && authUserInfo.id === feed.user_id &&
                                            <EllipsisDropdown
                                                handleDropdownSelection={handleDropdownSelection}
                                                data={feed}
                                                type={"post"}
                                                is_pinned={feed.is_pinned}
                                                isShowPinOnly={false}
                                                className="text-black"/>
                                        }
                                    </a>
                                </div>
                                <p>
                                    {feed?.title}
                                </p>
                            </div>

                            {displayCoverImage(feed, true)}

                            {/*{(feed && feed.attachments) &&*/}
                            {/*    <a className="flex justify-center">*/}
                            {/*        {(feed.attachments.videos && feed.attachments.videos.length > 0) ? (*/}
                            {/*                <ReactPlayer*/}
                            {/*                    playing={true}*/}
                            {/*                    loop={true}*/}
                            {/*                    controls={true}*/}
                            {/*                    muted={true}*/}
                            {/*                    width='100%'*/}
                            {/*                    height='100%'*/}
                            {/*                    className="max-h-element"*/}
                            {/*                    url={feed.attachments.videos[0].video}*/}
                            {/*                />*/}
                            {/*            )*/}
                            {/*            :*/}
                            {/*            <>*/}
                            {/*                {(feed.attachments.images && feed.attachments.images.length > 0) ? (*/}
                            {/*                        <div className="slide-container w-full">*/}
                            {/*                            <Slide*/}
                            {/*                                arrows={feed.attachments.images.length > 1}*/}
                            {/*                                indicators={feed.attachments.images.length > 1}*/}
                            {/*                                autoplay={false}*/}
                            {/*                            >*/}
                            {/*                                {feed.attachments.images.map((slideImage, index)=> (*/}
                            {/*                                    <img key={index}*/}
                            {/*                                         src={slideImage.image_medium} alt={`Slide ${index + 1}`}*/}
                            {/*                                         className="object-cover object-center group-hover:opacity-75 aspect-square w-full max-h-element"*/}
                            {/*                                    />*/}
                            {/*                                ))}*/}
                            {/*                            </Slide>*/}
                            {/*                        </div>*/}
                            {/*                    )*/}
                            {/*                    :*/}
                            {/*                    <>*/}
                            {/*                        {(feed.attachments.audios && feed.attachments.audios.length > 0) &&*/}
                            {/*                            <audio controls className="">*/}
                            {/*                                <source src={feed.attachments.audios[0].audio} type="audio/mpeg" />*/}
                            {/*                            </audio>*/}
                            {/*                        }*/}
                            {/*                    </>*/}
                            {/*                }*/}
                            {/*            </>*/}
                            {/*        }*/}
                            {/*    </a>*/}
                            {/*}*/}

                            <div className="stack-col p-article">
                                <div className="cs-stack-post-actions stack-row split">
                                    {/* CSJS Toggle to fa-solid when liked/clicked by User. fa-light state is unliked/not toggled. */}
                                    <div className="cs-stack-comment-hearts stack-row">
                                        <a className="button-icon-base-gradient"
                                           onClick={() => toggleHeart(feed.id, feed.is_hearted)}
                                        >
                                            <span className="sr-only">Love Post</span>
                                            <i className={`${feed.is_hearted ? 'fa-solid' : 'fa-light'} fa-heart`}/>
                                        </a>
                                        {feed.hearts_count > 0 &&
                                            <p>{feed.hearts_count}</p>
                                        }
                                    </div>

                                    <a className="button-icon-base-gradient"
                                       onClick={()=>setIsShareModalOpen(true)}
                                    >
                                        <span className="sr-only">Share Post</span>
                                        <i className="fa-light fa-share"/>
                                    </a>
                                </div>
                                <div className="cs-stack-post-activity stack-row-wrap split">
                                    <div className="stack-col sm:stack-row">
                                        <div className="stack-avatar">
                                            {feed && feed?.hearted_by_users && feed?.hearted_by_users.length > 0 && feed?.hearted_by_users.slice(0, 3).map((user, index) => (
                                                <div key={index} className="avatar-gradient relative inline-block">
                                                    <img alt="member-thumb" src={user.photo_thumb ? user.photo_thumb : default_user_profile} />
                                                    {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                </div>
                                            ))}
                                        </div>
                                        {feed && feed?.hearted_by_users && feed?.hearted_by_users.length > 0 && (
                                            <p>
                                                Loved by
                                                {feed && feed?.hearted_by_users && feed.hearts_count > 0 && feed.hearts_count > 3 ?
                                                    <>
                                                        {feed?.hearted_by_users.slice(0,3).map((user, index) => (
                                                            <a key={index} className='overflow-hidden ...'>
                                                                &nbsp;@{user.username ? user.username : user.full_name}
                                                            </a>
                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        {feed && feed?.hearted_by_users && feed.hearts_count > 0 && feed?.hearted_by_users.map((user, index) => (
                                                            <a key={index} className='overflow-hidden ...'>
                                                                {feed.hearts_count === 2 &&
                                                                    <>
                                                                        &nbsp;{1 === index ? ' and ' : ''}
                                                                    </>
                                                                }
                                                                &nbsp;@{user.username ? user.username : user.full_name}
                                                                {feed.hearts_count === 3 &&
                                                                    <>
                                                                        &nbsp;{0 === index ? ', ' :  1 === index ? ', and' : ''}
                                                                    </>
                                                                }
                                                            </a>
                                                        ))}
                                                    </>
                                                }
                                                {(feed && feed?.hearted_by_users && feed.hearts_count > 0 && feed.hearts_count > 3) &&
                                                    <a>
                                                        &nbsp;and {feed.hearts_count - 3} {(feed.hearts_count - 3) > 1 ? 'others' : 'other'}
                                                    </a>
                                                }
                                            </p>
                                        )}

                                    </div>
                                    <p>
                                        {(feed && feed.comments) ? feed.comments.length : '' } {(feed && feed.comments && feed.comments.length > 1) ? 'comments' : 'comment' }
                                    </p>
                                </div>
                                <div className="cs-stack-post-comment-form stack-row">
                                    <div className="avatar-gradient shrink-0">
                                        <img
                                            src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
                                            alt=""
                                        />
                                    </div>
                                    <Form className="w-full form-input-combo"
                                          name={`comment-${feed.id}`}
                                          id={`comment-${feed.id}`}
                                          // form={commentForm}
                                          // name="commentForm"
                                          onFinish={handleCommentSubmit}
                                          // onChange={handleCommentChanges}
                                          validateMessages={VALIDATE_MESSAGES}
                                    >
                                        <Form.Item
                                            name="comment"
                                            id="comment"
                                            className="w-full !p-0 !m-0"
                                        >
                                            <input
                                                placeholder="Add a comment..."
                                                value={formInputs[feed.id] || ""}
                                                onChange={(e) => handleInputChange(feed.id, e.target.value)}
                                                // maxRows={4}
                                                // onChange={handlePostChanges}
                                                onClick={() => setModelId(feed.id)}
                                                // onKeyPress={handlePostKeypress}
                                            />
                                        </Form.Item>
                                        <button
                                            disabled={isSubmitting}
                                            type="submit"
                                            className={'secondary-gradient'}
                                        >
                                            <span><p>Post</p></span>
                                        </button>
                                    </Form>
                                </div>

                                {/*BEGIN - COMMENT - DOUBLE NESTED REPLIES EXAMPLE*/}
                                {feed.comments && feed.comments.map((comment, index) => (
                                        <div key={index} className="cs-comment stack-col">
                                            <div className="stack-row">
                                                <a className="cs-comment-avatar relative shrink-0">
                                                    <img src={comment?.commenter?.photo_thumb ? comment?.commenter.photo_thumb : default_user_profile}
                                                         alt=""
                                                    />
                                                    {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                </a>

                                                <div className="cs-comment-thread stack-col">
                                                    <div className="cs-stack-comment-body stack-col-base">
                                                        <div className="cs-stack-comment-header stack-row split">
                                                            <div className="stack-row">
                                                                <div className="cs-stack-comment-published stack-col-base sm:stack-row">
                                                                    <h4>
                                                                        <a href="fam-profile.html">
                                                                            @{comment?.commenter?.username ? comment?.commenter?.username : (comment?.commenter?.full_name ? comment?.commenter?.full_name : '')}
                                                                        </a>
                                                                    </h4>
                                                                    <p>
                                                                        {getPostDate(comment?.created_on)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {authUserInfo && authUserInfo.id === comment?.user_id &&
                                                                <a className='button-icon-base-gradien' onClick={() => setIdsForDeletion(feed.id, comment?.id)}>
                                                                    <EllipsisDropdown handleDropdownSelection={handleDropdownSelection} data={comment} type={"comment"}/>
                                                                </a>
                                                            }
                                                        </div>
                                                        <p className="cs-comment-body">
                                                            {comment && (
                                                                comment?.comment
                                                            )}
                                                        </p>
                                                        {/*{commentId !== comment?.id && (*/}
                                                        <div className="cs-stack-comment-actions stack-row split">
                                                            {/*CSJS Toggle to fa-solid when liked/clicked by User. fa-light state is unliked/not toggled.*/}
                                                            <div className="cs-stack-comment-hearts stack-row">
                                                                <a className="button-icon-base-gradient"
                                                                   onClick={() => toggleHeartComment(feed?.id, comment?.is_hearted, comment?.id)}
                                                                >
                                                                    <span className="sr-only">Love Post</span>
                                                                    <i className={`${comment?.is_hearted ? 'fa-solid' : 'fa-light'} fa-heart`}/>
                                                                </a>
                                                                {(comment?.hearted_by_users && comment?.hearted_by_users.length > 0) &&
                                                                    <p>{comment?.hearted_by_users.length}</p>
                                                                }
                                                            </div>
                                                            <a className="button-icon-base-gradient" onClick={() => {
                                                                subCommentForm.resetFields();
                                                                subCommentReplyForm.resetFields();
                                                                setSubCommentIdOpened(null);
                                                                setCommentIdOpened(commentIdOpened === comment?.id ? null : comment?.id);
                                                            }}>
                                                                <span className="sr-only">Post Reply</span>
                                                                <i className={`${commentIdOpened === comment?.id ? 'fa-solid' : 'fa-light' } fa-reply`}/>
                                                            </a>
                                                        </div>
                                                        {/*)}*/}
                                                    </div>
                                                    {commentIdOpened === comment?.id && (
                                                        <div className="cs-stack-post-comment-form stack-row">
                                                            <a href="fam-profile.html" className="cs-comment-avatar shrink-0 relative">
                                                                <img
                                                                    src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
                                                                    alt=""
                                                                />
                                                                {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                            </a>
                                                            <Form className="w-full form-input-combo"
                                                                  form={subCommentForm}
                                                                  name="subCommentForm"
                                                                  onFinish={handleSubmitSubComment}
                                                                // onChange={handleCommentChanges()}
                                                                  validateMessages={VALIDATE_MESSAGES}
                                                            >
                                                                <Form.Item
                                                                    name="reply"
                                                                    id="reply"
                                                                    className="w-full !p-0 !m-0"
                                                                >
                                                                    <input
                                                                        placeholder={`Comment as @${authUserInfo?.profile?.username ? authUserInfo?.profile?.username : ''}`}
                                                                        // maxRows={4}
                                                                        // onChange={handlePostChanges}
                                                                        onClick={() => setReplyModelId(comment?.model_id, comment?.id)}
                                                                        // onKeyPress={handlePostKeypress}
                                                                    />
                                                                </Form.Item>
                                                                <button
                                                                    disabled={isSubmitting}
                                                                    type="submit"
                                                                    className={'secondary-gradient'}
                                                                >
                                                                    <span><p>Reply</p></span>
                                                                </button>
                                                            </Form>
                                                        </div>
                                                    )}
                                                    {/*BEGIN - Thread Reply - Nesting Point*/}

                                                    {/*BEGIN - COMMENT - SINGLE NESTED COMMENT*/}
                                                    {comment && comment?.replies && comment?.replies.map((reply, index) => (
                                                            <div key={index} className="cs-comment stack-col">
                                                                <div className="stack-row">
                                                                    <a href="fam-profile.html" className="cs-comment-avatar relative shrink-0">
                                                                        <img
                                                                            src={reply?.commenter?.photo_thumb ? reply.commenter.photo_thumb : default_user_profile}
                                                                            alt=""
                                                                        />
                                                                        {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                                    </a>
                                                                    <div className="cs-comment-thread stack-col">
                                                                        <div className="cs-stack-comment-body stack-col-base">
                                                                            <div className="cs-stack-comment-header stack-row split">
                                                                                <div className="stack-row">
                                                                                    <div className="cs-stack-comment-published stack-col-base sm:stack-row">
                                                                                        <h4>
                                                                                            <a href={reply?.commenter?.url}>
                                                                                                @{reply?.commenter?.username ? reply?.commenter?.username :
                                                                                                (reply?.commenter?.full_name ? reply?.commenter?.full_name : '')}
                                                                                            </a>
                                                                                        </h4>
                                                                                        <p>{getPostDate(reply?.created_on)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                {authUserInfo && authUserInfo.id === reply.user_id &&
                                                                                    <a className='button-icon-base-gradient' onClick={() => setIdsForDeletion(feed.id, comment?.id)}>
                                                                                        <EllipsisDropdown handleDropdownSelection={handleDropdownSelection} data={reply} type={"sub-comments"}/>
                                                                                    </a>
                                                                                }
                                                                            </div>
                                                                            <p className="cs-comment-body">
                                                                                {reply && (
                                                                                    reply.comment
                                                                                )}
                                                                            </p>
                                                                            {/*{commentId !== reply?.id && (*/}
                                                                            <div className="cs-stack-comment-actions stack-row split">
                                                                                {/*CSJS Toggle to fa-solid when liked/clicked by User. fa-light state is unliked/not toggled.*/}
                                                                                <div className="cs-stack-comment-hearts stack-row">
                                                                                    <a className="button-icon-base-gradient"
                                                                                       onClick={() => toggleHeartComment(feed?.id, reply?.is_hearted, comment?.id, reply?.id)}
                                                                                    >
                                                                                        <span className="sr-only">Love Post</span>
                                                                                        <i className={`${reply?.is_hearted ? 'fa-solid' : 'fa-light'} fa-heart`}/>
                                                                                    </a>
                                                                                    {reply && reply?.hearted_by_users && reply?.hearted_by_users.length > 0 &&
                                                                                        <p>{reply?.hearted_by_users.length}</p>
                                                                                    }
                                                                                </div>
                                                                                <a className="button-icon-base-gradient"
                                                                                   onClick={() => {
                                                                                       subCommentReplyForm.resetFields();
                                                                                       subCommentForm.resetFields();
                                                                                       setCommentIdOpened(null);
                                                                                       setReplyIdOpened(null);
                                                                                       setSubCommentIdOpened(subCommentIdOpened === reply?.id ? null : reply?.id);
                                                                                   }}
                                                                                >
                                                                                    <span className="sr-only">Post Reply</span>
                                                                                    <i className={`${subCommentIdOpened === reply?.id ? 'fa-solid' : 'fa-light'} fa-reply`}/>
                                                                                </a>
                                                                            </div>
                                                                            {/*)*/}
                                                                            {/*}*/}
                                                                            {subCommentIdOpened === reply?.id && (
                                                                                <div className="cs-stack-post-comment-form stack-row">
                                                                                    <a href="fam-profile.html" className="cs-comment-avatar shrink-0 relative">
                                                                                        <img
                                                                                            src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
                                                                                            alt=""
                                                                                        />
                                                                                        {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                                                    </a>
                                                                                    <Form className="w-full form-input-combo"
                                                                                          form={subCommentReplyForm}
                                                                                          name="subCommentRepyForm"
                                                                                          id="subCommentRepyForm"
                                                                                          onFinish={handleSubmitSubReply}
                                                                                        // onChange={handleCommentChanges()}
                                                                                          validateMessages={VALIDATE_MESSAGES}
                                                                                    >
                                                                                        <Form.Item
                                                                                            name="reply"
                                                                                            id="reply"
                                                                                            className="w-full !p-0 !m-0"
                                                                                        >
                                                                                            <input
                                                                                                placeholder={`Comment as @${authUserInfo?.profile?.username ? authUserInfo?.profile?.username : ''}`}
                                                                                                // maxRows={4}
                                                                                                // onChange={handlePostChanges}
                                                                                                onClick={() => setSubReplyModelId(comment?.model_id, comment?.id, reply?.id, null)}
                                                                                                // onKeyPress={handlePostKeypress}
                                                                                            />
                                                                                        </Form.Item>

                                                                                        <button
                                                                                            disabled={isSubmitting}
                                                                                            type="submit"
                                                                                            className={'secondary-gradient'}
                                                                                        >
                                                                                            <span><p>Reply</p></span>
                                                                                        </button>
                                                                                    </Form>
                                                                                </div>
                                                                            )}

                                                                        </div>
                                                                        {/*BEGIN - Thread Reply - Nesting Point*/}

                                                                        {/*BEGIN - COMMENT - DOUBLE NESTED COMMENT*/}
                                                                        {reply && reply.sub_replies && reply.sub_replies.map((sub_reply, index) => (
                                                                                <div key={index} className="cs-comment stack-col">
                                                                                    <div className="stack-row">
                                                                                        <a href="fam-profile.html" className="cs-comment-avatar relative shrink-0">
                                                                                            <img
                                                                                                src={sub_reply?.commenter?.photo_thumb ? sub_reply?.commenter?.photo_thumb  : default_user_profile}
                                                                                                alt=""
                                                                                            />
                                                                                            {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                                                        </a>
                                                                                        <div className="cs-comment-thread stack-col">
                                                                                            <div className="cs-stack-comment-body stack-col-base">
                                                                                                <div className="cs-stack-comment-header stack-row split">
                                                                                                    <div className="stack-row">
                                                                                                        <div className="cs-stack-comment-published stack-col-base sm:stack-row">
                                                                                                            <h4>
                                                                                                                <a href={sub_reply?.commenter?.url} className="gradient-color-txt font-bold">
                                                                                                                    @{sub_reply?.commenter?.username ? sub_reply?.commenter?.username :
                                                                                                                    (sub_reply?.commenter?.full_name ? sub_reply?.commenter?.full_name : '')}
                                                                                                                </a>
                                                                                                            </h4>
                                                                                                            <p>{getPostDate(reply?.created_on)}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {authUserInfo && authUserInfo.id === reply.user_id &&
                                                                                                        <a className='button-icon-base-gradient' onClick={() => setIdsForDeletion(feed?.id, comment?.id, reply?.id, sub_reply?.id)}>
                                                                                                            <EllipsisDropdown handleDropdownSelection={handleDropdownSelection} data={sub_reply} type={"sub-replies"}/>
                                                                                                        </a>
                                                                                                    }
                                                                                                </div>
                                                                                                <p className="cs-comment-body">
                                                                                                    {reply && (
                                                                                                        sub_reply.comment
                                                                                                    )}
                                                                                                </p>
                                                                                                {/*{commentId !== reply?.id && (*/}
                                                                                                <div className="cs-stack-comment-actions stack-row split">
                                                                                                    {/*CSJS Toggle to fa-solid when liked/clicked by User. fa-light state is unliked/not toggled.*/}
                                                                                                    <div className="cs-stack-comment-hearts stack-row">
                                                                                                        <a className="button-icon-base-gradient"
                                                                                                           onClick={() => toggleHeartComment(feed?.id, sub_reply?.is_hearted, comment?.id, reply?.id, sub_reply?.id)}
                                                                                                        >
                                                                                                            <span className="sr-only">Love Post</span>
                                                                                                            <i className={`${sub_reply?.is_hearted ? 'fa-solid' : 'fa-light'} fa-heart`}/>
                                                                                                        </a>
                                                                                                        {sub_reply && sub_reply?.hearted_by_users && sub_reply?.hearted_by_users.length > 0 &&
                                                                                                            <p>{sub_reply?.hearted_by_users.length}</p>
                                                                                                        }
                                                                                                    </div>
                                                                                                    {/*CSJS fa-solid class change when toggled/active. fa-light when inactive.*/}
                                                                                                    <a className="button-icon-base-gradient"
                                                                                                       onClick={() => {
                                                                                                           subCommentReplyForm.resetFields();
                                                                                                           subCommentForm.resetFields();
                                                                                                           setReplyIdOpened(replyIdOpened === sub_reply?.id ? null : sub_reply?.id);
                                                                                                           setCommentIdOpened(null);
                                                                                                           setSubCommentIdOpened(null);
                                                                                                       }
                                                                                                           // showSubCommentSubReply(sub_reply?.id)
                                                                                                       }
                                                                                                    >
                                                                                                        <span className="sr-only">Post Reply</span>
                                                                                                        <i className={`${replyIdOpened === sub_reply?.id ? 'fa-solid' : 'fa-light'} fa-reply`}/>
                                                                                                    </a>
                                                                                                </div>
                                                                                                {/*)}*/}

                                                                                            </div>
                                                                                            {/*BEGIN - Thread Reply - Nesting Point*/}
                                                                                            {/*/END - Thread Reply - Nesting Point*/}
                                                                                        </div>
                                                                                    </div>
                                                                                    {replyIdOpened === sub_reply?.id && (
                                                                                        <div className="cs-stack-post-comment-form stack-row">
                                                                                            <a href="fam-profile.html" className="cs-comment-avatar shrink-0 relative">
                                                                                                <img
                                                                                                    src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
                                                                                                    alt=""
                                                                                                />
                                                                                                {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                                                            </a>
                                                                                            <Form className="w-full form-input-combo"
                                                                                                  form={subCommentReplyForm}
                                                                                                  name="subCommentRepyForm"
                                                                                                  id="subCommentRepyForm"
                                                                                                  onFinish={handleSubmitSubReply}
                                                                                                // onChange={handleCommentChanges()}
                                                                                                  validateMessages={VALIDATE_MESSAGES}
                                                                                            >
                                                                                                <Form.Item
                                                                                                    name="reply"
                                                                                                    id="reply"
                                                                                                    className="w-full !p-0 !m-0"
                                                                                                >
                                                                                                    <input
                                                                                                        name="comment"
                                                                                                        placeholder={`Comment as @${authUserInfo?.profile?.username ? authUserInfo?.profile?.username : ''}`}
                                                                                                        // maxRows={4}
                                                                                                        // onChange={handlePostChanges}
                                                                                                        onClick={() => setSubReplyModelId(comment?.model_id, comment?.id, reply?.id)}
                                                                                                        // onKeyPress={handlePostKeypress}
                                                                                                    />
                                                                                                </Form.Item>

                                                                                                <button
                                                                                                    disabled={isSubmitting}
                                                                                                    type="submit"
                                                                                                    className={'secondary-gradient'}
                                                                                                >
                                                                                                    <span><p>Reply</p></span>
                                                                                                </button>
                                                                                            </Form>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                        {/*/END COMMENT - DOUBLE NESTED COMMENT*/}
                                                                        {/*/END - Thread Reply - Nesting Point*/}
                                                                    </div>
                                                                </div>
                                                                {/*BEGIN - Thread Reply - Inline Point*/}
                                                                {/*/END - Thread Reply - Inline Point*/}
                                                            </div>
                                                        )
                                                    )}
                                                    {/*/END COMMENT - SINGLE NESTED COMMENT*/}
                                                    {/*/END - Thread Reply - Nesting Point*/}
                                                </div>
                                            </div>
                                            {/*BEGIN - Thread Reply - Inline Point*/}
                                            {/*/END - Thread Reply - Inline Point*/}
                                        </div>
                                    )
                                )}
                                {/*/END COMMENT - DOUBLE NESTED REPLIES EXAMPLE*/}
                            </div>
                        </article>
                    ))}
                </div>
            </div>


            <div className="block-md space-y-0 order-1">
                <div className="stack-col">

                    <div className="cs-stack-channel-tools stack-col">
                        <div className="stack-row split">
                            <h2>Notifications</h2>
                            <div className="cs-stack-filters">
                                <button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>All</p><i className="fa-regular fa-angle-down"></i></button>
                            </div>
                        </div>
                    </div>

                    {/*<div className="w-full flex gap-x-4">*/}
                    {/*    <a className="cs-post-avatar relative inline-block">*/}
                    {/*        <img className="h-16 w-16 rounded-full object-cover"*/}
                    {/*             src={ default_user_profile}*/}
                    {/*             alt=""*/}
                    {/*        />*/}
                    {/*    </a>*/}
                    {/*    <hgroup>*/}
                    {/*        <div className='flex items-center'>*/}
                    {/*            <h4 className="flex-grow">*/}
                    {/*                <a>*/}
                    {/*                    @smi*/}
                    {/*                </a>*/}
                    {/*            </h4>*/}
                    {/*            <i className="fa-regular fa-user-plus"/>*/}
                    {/*            /!*<i className="fa-regular fa-message"/>*!/*/}
                    {/*            /!*<i className="fa-regular fa-comment"/>*!/*/}
                    {/*            /!*<i className="fa-regular fa-gift"/>*!/*/}
                    {/*        </div>*/}
                    {/*        <p className="content-notif">*/}
                    {/*            followed you. followed you. followed you. followed you. followed you.*/}
                    {/*        </p>*/}
                    {/*        <p className="text-color-light italic">12 minutes ago</p>*/}
                    {/*    </hgroup>*/}
                    {/*</div>*/}

                </div>
            </div>
        </div>



        // <div className="block-full">
        //     <div className="grid grid-cols-12 gap-2 md:gap-8">
        //         <div className="col-span-12 md:col-span-6 block-lg card">
        //             <div className="block-full">
        //                 <h5 className="header-small text-center">{totalMembersCount}</h5>
        //                 <p className="body-text--smallest text-center">Total Members</p>
        //                 {/*<div className="grid grid-cols-1 md:grid-cols-3 justify-evenly mt-5">*/}
        //                 {/*	<div className="text-center ">*/}
        //                 {/*		<p className="body-text--smallest gradient-color-txt mb-2">*/}
        //                 {/*			+{newMembersTodayCount}*/}
        //                 {/*		</p>*/}
        //                 {/*		<p className="body-text--tiny m-0">New Today</p>*/}
        //                 {/*	</div>*/}
        //                 {/*	<div className="text-center">*/}
        //                 {/*		<p className="body-text--smallest gradient-color-txt mb-2">*/}
        //                 {/*			+{newMembersMonthCount}*/}
        //                 {/*		</p>*/}
        //                 {/*		<p className="body-text--tiny m-0">New This Month</p>*/}
        //                 {/*	</div>*/}
        //                 {/*	<div className="text-center">*/}
        //                 {/*		<p className="body-text--smallest gradient-color-txt mb-2">*/}
        //                 {/*			+{lostMembersMonthCount}*/}
        //                 {/*		</p>*/}
        //                 {/*		<p className="body-text--tiny m-0">Lost This Month</p>*/}
        //                 {/*	</div>*/}
        //                 {/*</div>*/}
        //             </div>
        //         </div>
        //         <div className="col-span-12 md:col-span-6 block-lg card">
        //             <div className="dashboard-wrapper">
        //                 <div className="block-full">
        //                     <h5 className="header-small text-center">
        //                         {totalActiveMembersCount}(
        //                         {getPercentage(totalMembersCount, totalActiveMembersCount)})
        //                     </h5>
        //                     <p className="body-text--smallest text-center">Recently Active</p>
        //                     {/*<div className="grid grid-cols-1 md:grid-cols-3 justify-evenly mt-5">*/}
        //                     {/*	<div className="text-center">*/}
        //                     {/*		<p className="body-text--smallest gradient-color-txt mb-2">*/}
        //                     {/*			{totalActiveMembersCount} (*/}
        //                     {/*			{getPercentage(totalMembersCount, dailyActiveCount)})*/}
        //                     {/*		</p>*/}
        //                     {/*		<p className="body-text--tiny m-0">Daily Active</p>*/}
        //                     {/*	</div>*/}
        //                     {/*	<div className="text-center">*/}
        //                     {/*		<p className="body-text--smallest gradient-color-txt mb-2">*/}
        //                     {/*			{totalActiveMembersCount} (*/}
        //                     {/*			{getPercentage(totalMembersCount, weeklyActiveCount)})*/}
        //                     {/*		</p>*/}
        //                     {/*		<p className="body-text--tiny m-0">Weekly Active</p>*/}
        //                     {/*	</div>*/}
        //                     {/*	<div className="text-center">*/}
        //                     {/*		<p className="body-text--smallest gradient-color-txt mb-2">*/}
        //                     {/*			{totalActiveMembersCount} (*/}
        //                     {/*			{getPercentage(totalMembersCount, monthlyActiveCount)})*/}
        //                     {/*		</p>*/}
        //                     {/*		<p className="body-text--tiny m-0">Monthly Active</p>*/}
        //                     {/*	</div>*/}
        //                     {/*</div>*/}
        //                 </div>
        //             </div>
        //         </div>
        //
		// 		<div className="block-lg card">
		// 			<div id="cs-platform-dash-item" className="cs-dash">
		// 				<div className="cs-dashframe section-flex text-center">
		// 					<div className="block-full">
		// 						<h5 className="header-small text-center">{totalSoldItemsCount}</h5>
		// 						<p className=" !mt-0">Item Sold Today</p>
		// 					</div>
		// 					{/*<div className="block-smx ">*/}
		// 					{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
		// 					{/*        {averageSoldPerMember}*/}
		// 					{/*    </p>*/}
		// 					{/*    <p className="text-p5 !mt-0">Average Per Member</p>*/}
		// 					{/*</div>*/}
		// 					{/*<div className="block-smx">*/}
		// 					{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
		// 					{/*        {totalSoldItemsThisMonthCount}*/}
		// 					{/*    </p>*/}
		// 					{/*    <p className="text-p5  !mt-0">Sold This Month</p>*/}
		// 					{/*</div>*/}
		// 					{/*<div className="block-smx">*/}
		// 					{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
		// 					{/*        {mostPopularItem}*/}
		// 					{/*    </p>*/}
		// 					{/*    <p className="text-p5  !mt-0">Most Popular</p>*/}
		// 					{/*</div>*/}
		// 				</div>
		// 			</div>
		// 		</div>
		// 		<div className="block-lg card">
		// 			<div id="cs-platform-dash-item" className="cs-dash">
		// 				<div className="cs-dashframe section-flex text-center">
		// 					<div className="block-full">
		// 						<h5 className="header-small text-center">${totalActiveItemsValue} USD</h5>
		// 						<p className=" !mt-0">Recently Active</p>
		// 					</div>
		// 					{/*<div className="block-smx">*/}
		// 					{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
		// 					{/*        {averagePerMemberValue}*/}
		// 					{/*    </p>*/}
		// 					{/*    <p className="text-p5  !mt-0">Average Per Member</p>*/}
		// 					{/*</div>*/}
		// 					{/*<div className="block-smx">*/}
		// 					{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
		// 					{/*        {salesThisMonthValue}*/}
		// 					{/*    </p>*/}
		// 					{/*    <p className="text-p5  !mt-0">Sales This Month</p>*/}
		// 					{/*</div>*/}
		// 					{/*<div className="block-smx">*/}
		// 					{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
		// 					{/*        {recurringRevenueValue}*/}
		// 					{/*    </p>*/}
		// 					{/*    <p className="text-p5  !mt-0">Recurring Revenue</p>*/}
		// 					{/*</div>*/}
		// 				</div>
		// 			</div>
		// 		</div>
        //     </div>
        // </div>
    );
};

export default memo(DashboardHome);
