import {memo, useCallback, useEffect, useState} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import routes from '../../../routes';
import ProfileContent from '../../Profile/ProfileContent';
import SettingsContent from '../../Settings/SettingsContent';
import Manage2FA from '../../Settings/SettingsContent/Manage2FA';
import ManageNotificationPreferences from '../../Settings/SettingsContent/ManageNotificationPreferences';
import ManagePaymentMethods from '../../Settings/SettingsContent/ManagePaymentMethods';
import ManageMagicWallet from '../../Settings/SettingsContent/ManageWallets';
import SpacesContent from '../../Spaces/SpacesContent';
import ManageSpaceMembership from '../../Spaces/SpacesContent/ManageSpaceMembership';
import CollectionsContent from '../../Collections/CollectionsContent';
import ViewCollection from '../../Collections/CollectionsContent/ViewCollection';
import ViewCollectionItem from '../../Collections/CollectionsContent/ViewItem';
import DashboardHome from '../../Dashboard/DashboardHome';

import default_space_image from '../../../assets/images/profile_spaces_img.png';
import sample_thumb from '../../../assets/images/sample_thumb.png';
import default_space_bg from '../../../assets/images/profile_spaces_bg.png';
import '../Dashboard.scss';
import ViewTransactions from '../../Settings/SettingsContent/ViewTransactions';
import {getSpaceInfo} from "../../../states/spaceGlobal/actions";
import {useTranslation} from "react-i18next";
import CollectionManageMembership from "../../Collections/Membership/CollectionManageMembership";
import {getSpaceMembership} from "../../../services/space";
import ActivityContent from "../../Activity/ActivityContent";

const DashboardContent = () => {
    const { t, i18n } = useTranslation();
    const {space_username} = useParams();
    const {membership_id} = useParams();
    const currentLocation = useLocation();
    const dispatch = useDispatch();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);
    const [isManageSpaceUrl, setIsManageSpaceUrl] = useState(false);
    const [isViewCollectionUrl, setIsViewCollectionUrl] = useState(false);
    const [isViewCollectionItemUrl, setIsViewCollectionItemUrl] = useState(false);
    const [isViewCollectionManageMembershipUrl, setIsViewCollectionManageMembershipUrl] = useState(false);
    const [membershipID, setMembershipID] = useState();
    const [membershipData, setMembershipData] = useState();
    const dashboardUrls = [routes.dashboard()];
    const activityUrls = [routes.activities()];
    const spacesUrls = [routes.spaces(), routes.manageSpaceMembership(), routes.manageSpaceMembershipExpired()];
    const collectionsUrls = [routes.collections(), routes.viewCollection(), routes.viewCollectionManageMembership()];
    const [manageCollectionMembership, setManageCollectionMembership] = useState();

    const profileUrls = [
        routes.profile(),
        routes.profileEdit(),
        routes.profileView(),
        routes.profileEditAboutSection(),
        routes.profileManageFollowers(),
        routes.profileManageContact(),
        routes.profileManageVisibility()
    ];

    const settingsUrls = [
        routes.settings(),
        routes.settings2FA(),
        routes.settingsNotificationPreferences(),
        routes.settingsPaymentMethods(),
        routes.settingsMagicWallet(),
        routes.settingsTransactions()
    ];

    const secondaryProfileUrls = [
        routes.profileEdit(),
        routes.profileView(),
        routes.profileEditAboutSection(),
        routes.profileManageFollowers(),
        routes.profileManageContact(),
        routes.profileManageVisibility(),
    ];

    const secondarySettingsUrls = [
        routes.settings2FA(),
        routes.settingsNotificationPreferences(),
        routes.settingsPaymentMethods(),
        routes.settingsMagicWallet(),
        routes.settingsTransactions()
    ];

    const [selectedTab, setSelectedTab] = useState(routes.dashboard());

    const getMembership = useCallback(async () => {
        const data = {
            membership_id: membership_id,
            is_get_members: true,
            is_get_payment_transaction: true
        };
        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }
        const response = await getSpaceMembership(data);
        if (response && response.result) {
            const membership = response.membership;
            setMembershipData(membership);
        }
    }, [membershipData, authUserInfo]);

    const setActiveLink = (links, boolean = false) => {
        // console.log('links')
        // console.log(links)
        // console.log('pathname')
        // console.log(location.pathname)
        if (links.includes(location.pathname)) {
            if (boolean) {
                return true;
            }
            return 'active';
        }
        if (boolean) {
            return false;
        }
        return '';
    };

    useEffect(() => {
        setSelectedTab(currentLocation.pathname);

        const currentPath = currentLocation.pathname;
        // let membershipID = '';
        if (currentPath.includes('/manage/active/membership') || currentPath.includes('/manage/inactive/membership')) {
            const pathParts = currentPath.split('/');
            // membershipID = pathParts.pop();
            setMembershipID(pathParts.pop())
        }

        const manageSpaceRoute = routes.manageSpaceMembership();
        const finManageSpaceRoute = manageSpaceRoute.replace(':space_username', space_username)
            .replace(':membership_id', membershipID);

        const manageSpaceMembershipRoute = routes.manageSpaceMembershipExpired();
        const finManageSpaceMembershipRoute = manageSpaceMembershipRoute.replace(':space_username', space_username)
            .replace(':membership_id', membershipID);

        const collectionsViewRoute = routes.viewCollection();
        const finCollectionsViewRoute = collectionsViewRoute.replace(
            ':space_username',
            space_username
        );

        const collectionsViewItemRoute = routes.viewCollectionItem();
        const finCollectionsViewItemRoute = collectionsViewItemRoute.replace(
            ':space_username',
            space_username
        );

        const collectionsManageMembership = routes.viewCollectionManageMembership();
        const finCollectionsManageMembershipRoute = collectionsManageMembership.replace(
            ':space_username',
            space_username
        ).replace(':membership_id', membership_id);

        setManageCollectionMembership(finCollectionsManageMembershipRoute);

        setIsManageSpaceUrl(false);
        if (currentLocation.pathname === finManageSpaceRoute
            || currentLocation.pathname === finManageSpaceMembershipRoute
        ) {
            setIsManageSpaceUrl(true);
        }

        setIsViewCollectionUrl(false);
        if (currentLocation.pathname === finCollectionsViewRoute) {
            setIsViewCollectionUrl(true);
        }

        setIsViewCollectionItemUrl(false);
        if (currentLocation.pathname === finCollectionsViewItemRoute) {
            setIsViewCollectionItemUrl(true);
        }

        setIsViewCollectionManageMembershipUrl(false);
        if (currentLocation.pathname === finCollectionsManageMembershipRoute) {
            setIsViewCollectionManageMembershipUrl(true);
        }

    }, [currentLocation.pathname, space_username, authUserInfo, spaceInfo]);

    useEffect(() => {
        if (authUserInfo) {
            dispatch(getSpaceInfo(space_username, authUserInfo.id));
        }
    }, [space_username, authUserInfo])

    useEffect(() => {
        if (authUserInfo) {
            getMembership().then();
        }
    }, [authUserInfo]);

    return (
        <div className="centerframe-grid">
            <div className="cs-dash-breadcrumbs block-full">
                <nav>
                    <p>
                        <Link to={routes.viewUserSpace() + (authUserInfo?.profile?.username ?? '') + '/about'}>
                            {authUserInfo && authUserInfo.profile && (
                                <>
                                    {authUserInfo.profile.username
                                        ? '@' + authUserInfo.profile.username
                                        : authUserInfo.profile.first_name}
                                </>
                            )}
                        </Link>
                    </p>
                    {setActiveLink(dashboardUrls, true) && (
                        <p>
                            <Link to={routes.dashboard()} className="active">Dashboard</Link>
                        </p>
                    )}
                    {setActiveLink(activityUrls, true) && (
                        <p>
                            <Link to={routes.activities()} className="active">Activity</Link>
                        </p>
                    )}
                    {(setActiveLink(spacesUrls, true) || isManageSpaceUrl) && (
                        <p>
                            <Link to={routes.spaces()} className="active">Spaces</Link>
                        </p>
                    )}
                    {(setActiveLink(collectionsUrls, true) ||
                        isViewCollectionUrl ||
                        isViewCollectionItemUrl || isViewCollectionManageMembershipUrl) && (
                        <p>
                            <Link to={routes.collections()} className={`${isViewCollectionManageMembershipUrl ? '' : 'active'}`}>Collections</Link>
                        </p>
                    )}
                    {setActiveLink(profileUrls, true) && (
                        <p>
                            <Link to={routes.profile()} className="active">Profile</Link>
                        </p>
                    )}
                    {setActiveLink(settingsUrls, true) && (
                        <p>
                            <Link to={routes.settings()} className="active">Account Settings</Link>
                        </p>
                    )}


                    {isViewCollectionManageMembershipUrl && (
                        <>
                            <p>
                                <Link to={routes.viewUserSpace() + space_username }>
                                    {space_username}
                                </Link>
                            </p>
                            <p>
                                <Link onClick={()=>{window.location.replace(routes.viewUserSpace() + space_username +'/member')}}>
                                    Membership
                                </Link>
                            </p>
                            <p>
                                <Link className="active">
                                    {membershipData?.name}
                                </Link>
                            </p>
                        </>
                    )}


                    {setActiveLink(secondaryProfileUrls, true) && (
                        <p>
                            {selectedTab === routes.profileEdit() && (
                                <Link to={routes.profileEdit()} className="active">
                                    Edit
                                </Link>
                            )}
                            {selectedTab === routes.profileView() && (
                                <Link to={routes.profileView()} className="active">
                                    View
                                </Link>
                            )}
                            {selectedTab === routes.profileEditAboutSection() && (
                                <Link to={routes.profileEditAboutSection()} className="active">
                                    Edit About Section
                                </Link>
                            )}
                            {selectedTab === routes.profileManageFollowers() && (
                                <Link to={routes.profileManageFollowers()} className="active">
                                    Manage Followers
                                </Link>
                            )}
                            {selectedTab === routes.profileManageContact() && (
                                <Link to={routes.profileManageContact()} className="active">
                                    Manage Contact
                                </Link>
                            )}
                            {selectedTab === routes.profileManageVisibility() && (
                                <Link to={routes.profileManageVisibility()} className="active">
                                    Manage Visibility
                                </Link>
                            )}
                        </p>
                    )}
                    {setActiveLink(secondarySettingsUrls, true) && (
                        <p>
                            {selectedTab === routes.settings2FA() && (
                                <Link to={routes.settings2FA()} className="active">
                                    2 Factor Authentication
                                </Link>
                            )}
                            {selectedTab === routes.settingsNotificationPreferences() && (
                                <Link to={routes.settingsNotificationPreferences()} className="active">
                                    Notification
                                </Link>
                            )}
                            {selectedTab === routes.settingsPaymentMethods() && (
                                <Link to={routes.settingsPaymentMethods()} className="active">
                                    Payment Methods
                                </Link>
                            )}
                            {selectedTab === routes.settingsMagicWallet() && (
                                <Link to={routes.settingsMagicWallet()} className="active">
                                    Magic Wallet
                                </Link>
                            )}
                            {selectedTab === routes.settingsTransactions() && (
                                <Link to={routes.settingsTransactions()} className="active">
                                    Transactions
                                </Link>
                            )}
                        </p>
                    )}
                    {isManageSpaceUrl && (
                        <p>
                            <Link to="#" className="active">{space_username}</Link>
                        </p>
                    )}
                    {isViewCollectionUrl && (
                        <p>
                            <Link to="#" className="active">{space_username}</Link>
                        </p>
                    )}

                    {isViewCollectionItemUrl && (
                        <>
                            <p>
                                <Link to="#" className="active">{space_username}</Link>
                            </p>
                            <p>
                                <Link to="#" className="active">Item Name Here</Link>
                            </p>
                        </>
                    )}
                </nav>
            </div>

            <div id="cs-platform-dash-nav" className="cs-dash-nav">
                <nav>
                    <Link to={routes.dashboard()} className={setActiveLink(dashboardUrls) + ` button-gradient-air`}>
                        <div>
                            <span><i className="fa-light fa-chart-tree-map"></i></span>
                            <p>{t('nav_dashboard')}</p>
                        </div>
                    </Link>
                    <Link to={routes.activities()} className={setActiveLink([routes.activities()]) + `button-gradient-air`}>
                        <div>
                            <span><i className="fa-light fa-list-ul"></i></span>
                            <p>Activity</p>
                        </div>
                    </Link>
                    <Link to={routes.spaces()} className={setActiveLink(spacesUrls) + ` button-gradient-air`}>
                        <div>
                            <span><i className="fa-light fa-solar-system"></i></span>
                            <p>{t('nav_spaces')}</p>
                        </div>
                    </Link>
                    <Link to={routes.collections()} className={setActiveLink(collectionsUrls) + ` button-gradient-air`}>
                        <div>
                            <span><i className="fa-light fa-grid"></i></span>
                            <p>{t('nav_collections')}</p>
                        </div>
                    </Link>
                    <Link to={routes.profile()} className={setActiveLink(profileUrls) + ` button-gradient-air`}>
                        <div>
                            <span><i className="fa-light fa-id-badge"></i></span>
                            <p>{t('nav_profile')}</p>
                        </div>
                    </Link>
                    <Link to={routes.settings()} className={setActiveLink(settingsUrls) + ` button-gradient-air`}>
                        <div>
                            <span><i className="fa-light fa-gear"></i></span>
                            <p>{t('nav_account')}</p>
                        </div>
                    </Link>
                    <Link to={routes.logout()} className={`button-gradient-air`}>
                        <div>
                            <span><i className="fa-light fa-arrow-right-from-bracket"></i></span>
                            <p>{t('nav_logout')}</p>
                        </div>
                    </Link>
                </nav>
            </div>
            <div id="cs-platform-dash" className="cs-dash">
                <div className={isViewCollectionUrl ? "cs-dashframe section-grid" : "cs-dashframe section-flex"}>
                    {profileUrls.includes(location.pathname) && <ProfileContent/>}
                    {selectedTab === routes.settings() && <SettingsContent/>}
                    {selectedTab === routes.profileCommunityBios() && <ProfileContent/>}
                    {selectedTab === routes.settings2FA() && <Manage2FA/>}
                    {selectedTab === routes.settingsNotificationPreferences() && (
                        <ManageNotificationPreferences/>
                    )}
                    {selectedTab === routes.settingsPaymentMethods() && <ManagePaymentMethods/>}
                    {selectedTab === routes.settingsMagicWallet() && <ManageMagicWallet/>}
                    {selectedTab === routes.settingsTransactions() && <ViewTransactions/>}
                    {selectedTab === routes.spaces() && <SpacesContent/>}
                    {isManageSpaceUrl && <ManageSpaceMembership
                        user={authUserInfo}
                        space={spaceInfo}
                        membershipID={membershipID}/>}
                    {selectedTab === routes.collections() && <CollectionsContent/>}
                    {selectedTab === manageCollectionMembership &&
                        <CollectionManageMembership
                            spaceInfo={spaceInfo}
                            authUserInfo={authUserInfo}
                            membershipData={membershipData}
                            spaceOwnerUsername={space_username}
                        />
                    }
                    {isViewCollectionUrl && <ViewCollection/>}
                    {isViewCollectionItemUrl && <ViewCollectionItem/>}

                    {selectedTab === routes.dashboard() && <DashboardHome/>}
                    {selectedTab === routes.activities() && <ActivityContent/>}
                </div>
            </div>
        </div>
    );
};

export default memo(DashboardContent);
